export const getData = key => {
  try {
    let userString = window.localStorage.getItem(key);
    let userArr = JSON.parse(userString);
    return userArr;
  } catch (err) {
    return new Object();
  }
};

export const deleteData = key => {
  window.localStorage.removeItem(key);
};

export const saveData = (key, data) => {
  window.localStorage.removeItem(key);
  window.localStorage.setItem(key, JSON.stringify(data));
};

export default { saveData, getData, deleteData };
