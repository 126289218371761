import LocalService from "@/core/services/local.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const LoggedInUser = LocalService.getData("current_user") || new Object();

const state = {
  user_personal_info: {
    photo: LoggedInUser.profile_logo || "",
    name: LoggedInUser.first_name || "",
    surname: LoggedInUser.last_name || "",
    company_name: LoggedInUser.company_name || "",
    job: LoggedInUser.role || "",
    email: LoggedInUser.user_email || "",
    phone: LoggedInUser.phone_number || ""
  }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
