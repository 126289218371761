import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "check.authentication" }
    },
    {
      path: "/authenticate",
      name: "check.authentication",
      component: () => import("@/view/pages/Authentication")
    },
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "admin.dashboard" },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "service-form-report",
          name: "admin.service.form.report",
          component: () => import("@/view/pages/service-form/Report"),
          meta: {
            requiresAuth: true,
            menuActive: "service-form-report",
            title: "Service Form Report"
          }
        },
        {
          path: "dashboard",
          name: "admin.dashboard",
          component: () => import("@/view/pages/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard"
          }
        },
        {
          path: "service-form",
          name: "admin.service-form",
          component: () => import("@/view/pages/Service-Form"),
          meta: {
            requiresAuth: true,
            menuActive: "service-form",
            title: "Dashboard"
          }
        },
        {
          path: "profile",
          name: "admin.profile",
          redirect: { name: "admin.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "admin.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile"
              }
            },
            {
              path: "setting",
              name: "admin.profile.setting",
              component: () => import("@/view/pages/profile/Setting.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting"
              }
            },
            {
              path: "activity",
              name: "admin.profile.activity",
              component: () => import("@/view/pages/profile/Activity.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "activity",
                title: "Activity"
              }
            },
            {
              path: "reminders",
              name: "admin.profile.reminders",
              component: () => import("@/view/pages/profile/Reminder.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "reminders",
                title: "Reminder"
              }
            }
          ]
        },
        {
          path: "project/costing/create",
          name: "admin.project.costing.create",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Costing | Projects"
          }
        },
        {
          path: "project/costing/update/:id",
          name: "admin.project.costing.update",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Costing | Projects"
          }
        },
        {
          path: "project/costing/detail/:id",
          name: "admin.project.costing.detail",
          component: () =>
            import("@/view/pages/project/costing/DetailCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Costing | Projects"
          }
        },
        {
          path: "project",
          name: "admin.project",
          component: () => import("@/view/pages/project/Project.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "project",
            title: "Projects"
          }
        },
        {
          path: "project/create",
          name: "admin.project.create",
          component: () => import("@/view/pages/project/Create-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Project"
          }
        },
        {
          path: "project/update/:id",
          name: "admin.project.update",
          component: () => import("@/view/pages/project/Update-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Project"
          }
        },
        {
          path: "project/detail/:id",
          name: "admin.project.detail",
          component: () => import("@/view/pages/project/Detail-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Project"
          }
        },
        {
          path: "purchase-order",
          name: "admin.purchase.order",
          component: () =>
            import("@/view/pages/purchase-order/Purchase-Order.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Purchase Orders"
          }
        },
        {
          path: "purchase-order/create",
          name: "admin.purchase.order.create",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Create-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Create Purchase Order"
          }
        },
        {
          path: "purchase-order/update/:id",
          name: "admin.purchase.order.update",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Update-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Update Purchase Order"
          }
        },
        {
          path: "purchase-order/detail/:id",
          name: "admin.purchase.order.detail",
          component: () =>
            import(
              "@/view/pages/purchase-order/Detail/Detail-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Detail Purchase Order"
          }
        },
        {
          path: "bug-reports",
          name: "admin.bug.report",
          component: () => import("@/view/pages/bug-report/Bug-Report.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            title: "Bug Report"
          }
        },
        {
          path: "bug-reports/create",
          name: "admin.create.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Create-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Bug Report"
          }
        },
        {
          path: "bug-reports/detail/:id",
          name: "admin.detail.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Detail-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Detail Bug Report"
          }
        },
        {
          path: "email-template/update/:id",
          name: "admin.email.template.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateEmailTemplate.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Email Template"
          }
        },
        {
          path: "role/create",
          name: "admin.role.create",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Role"
          }
        },
        {
          path: "role/update/:id",
          name: "admin.role.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Role"
          }
        },
        {
          path: "users",
          name: "admin.profile.users",
          component: () => import("@/view/pages/profile/Users.vue"),
          meta: {
            requiresAuth: true,
            title: "User"
          }
        },
        {
          path: "users/permission/:id",
          name: "admin.profile.userpermission",
          component: () => import("@/view/pages/profile/UserPermission.vue"),
          meta: {
            requiresAuth: true,
            title: "Users Permission"
          }
        },
        {
          path: "calendar",
          name: "admin.calendar",
          component: () => import("@/view/pages/Calendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Calendar"
          }
        },
        {
          path: "calendar/resource",
          name: "admin.calendar.resource",
          component: () => import("@/view/pages/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar"
          }
        },
        {
          path: "customer",
          name: "admin.customer",
          component: () => import("@/view/pages/customer/Customer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "customer",
            title: "Customer"
          }
        },
        {
          path: "customer/create",
          name: "admin.customer.create",
          component: () => import("@/view/pages/customer/Create-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Create | Customer"
          }
        },
        {
          path: "customer/detail/:id",
          name: "admin.customer.detail",
          component: () => import("@/view/pages/customer/Detail-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Detail | Customer"
          }
        },
        {
          path: "supplier",
          name: "admin.supplier",
          component: () => import("@/view/pages/supplier/Supplier"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "supplier",
            title: "Supplier"
          }
        },
        {
          path: "supplier/create",
          name: "admin.supplier.create",
          component: () => import("@/view/pages/supplier/Create-Supplier"),
          meta: {
            requiresAuth: true,
            menuActive: "supplier",
            title: "Create | Supplier"
          }
        },
        {
          path: "supplier/detail/:id",
          name: "admin.supplier.detail",
          component: () => import("@/view/pages/supplier/Detail-Supplier"),
          meta: {
            requiresAuth: true,
            menuActive: "supplier",
            title: "Detail | Supplier"
          }
        },
        {
          path: "user",
          name: "admin.user",
          component: () => import("@/view/pages/user/User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "User"
          }
        },
        {
          path: "user/create",
          name: "admin.user.create",
          component: () => import("@/view/pages/user/Create-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Create | User"
          }
        },
        {
          path: "user/detail/:id",
          name: "admin.user.detail",
          component: () => import("@/view/pages/user/Detail-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Detail | User"
          }
        },
        {
          path: "property",
          name: "admin.property",
          component: () => import("@/view/pages/property/Property"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "property",
            title: "Property"
          }
        },
        {
          path: "property/create",
          name: "admin.property.create",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Create | Property"
          }
        },
        {
          path: "property/detail/:id",
          name: "admin.property.detail",
          component: () => import("@/view/pages/property/Detail-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Detail | Property"
          }
        },
        {
          path: "property/update/:id",
          name: "admin.property.update",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Update | Property"
          }
        },
        {
          path: "quotation",
          name: "admin.quotation",
          component: () => import("@/view/pages/quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "quotation",
            title: "Quotation"
          }
        },
        {
          path: "quotation/create",
          name: "admin.quotation.create",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Create | Quotation"
          }
        },
        {
          path: "quotation/update/:id",
          name: "admin.quotation.update",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Update | Quotation"
          }
        },
        {
          path: "quotation/detail/:id",
          name: "admin.quotation.detail",
          component: () => import("@/view/pages/quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Detail | Quotation"
          }
        },
        {
          path: "invoice",
          name: "admin.invoice",
          component: () => import("@/view/pages/invoice/Invoice"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "invoice",
            title: "Invoice"
          }
        },
        {
          path: "invoice/create",
          name: "admin.invoice.create",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Create | Invoice"
          }
        },
        {
          path: "invoice/update/:id",
          name: "admin.invoice.update",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Update | Invoice"
          }
        },
        {
          path: "invoice/detail/:id",
          name: "admin.invoice.detail",
          component: () => import("@/view/pages/invoice/Detail-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Detail | Invoice"
          }
        },
        {
          path: "task",
          name: "admin.task",
          component: () => import("@/view/pages/task/Task"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "task",
            title: "Task"
          }
        },
        {
          path: "task/canban",
          name: "admin.task.canban",
          component: () => import("@/view/pages/task/Canban-Task"),
          meta: { requiresAuth: true, menuActive: "task", title: "Task" }
        },
        {
          path: "task/create",
          name: "admin.task.create",
          component: () =>
            import("@/view/pages/task/Create-Or-Update/Create-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "task",
            title: "Create | Task"
          }
        },
        {
          path: "task/update/:id",
          name: "admin.task.update",
          component: () =>
            import("@/view/pages/task/Create-Or-Update/Update-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "task",
            title: "Update | Task"
          }
        },
        {
          path: "task/detail/:id",
          name: "admin.task.detail",
          component: () => import("@/view/pages/task/Detail/Detail-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "task",
            title: "Detail | Task"
          }
        },
        {
          path: "knowledge-base",
          name: "admin.knowledge.base",
          component: () => import("@/view/pages/knowledge-base/Knowledge-Base"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "knowledge-base",
            title: "Knowledge Base"
          }
        },
        {
          path: "knowledge-base/create",
          name: "admin.knowledge.base.create",
          component: () =>
            import(
              "@/view/pages/knowledge-base/Create-Or-Update/Create-Knowledge-Base"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "knowledge-base",
            title: "Create | Knowledge Base"
          }
        },
        {
          path: "knowledge-base/update/:id",
          name: "admin.knowledge.base.update",
          component: () =>
            import(
              "@/view/pages/knowledge-base/Create-Or-Update/Create-Knowledge-Base"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "knowledge-base",
            title: "Update | Knowledge Base"
          }
        },
        {
          path: "knowledge-base/detail/:id",
          name: "admin.knowledge.base.detail",
          component: () =>
            import("@/view/pages/knowledge-base/Detail/Detail-Knowledge-Base"),
          meta: {
            requiresAuth: true,
            menuActive: "knowledge-base",
            title: "Detail | Knowledge Base"
          }
        },
        {
          path: "leads",
          name: "admin.lead",
          component: () => import("@/view/pages/lead/Lead"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "lead",
            title: "Lead"
          }
        },
        {
          path: "leads/create",
          name: "admin.lead.create",
          component: () =>
            import("@/view/pages/lead/Create-Or-Update/Create-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Create | Lead"
          }
        },
        {
          path: "leads/update/:id",
          name: "admin.lead.update",
          component: () =>
            import("@/view/pages/lead/Create-Or-Update/Update-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Update | Lead"
          }
        },
        {
          path: "lead/detail/:id",
          name: "admin.lead.detail",
          component: () => import("@/view/pages/lead/Detail/Detail-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Detail | Lead"
          }
        },
        {
          path: "contracts",
          name: "admin.contract",
          component: () => import("@/view/pages/contract/Contract"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "contract",
            title: "Contracts"
          }
        },
        {
          path: "contracts/create",
          name: "admin.contract.create",
          component: () =>
            import("@/view/pages/contract/Create-Or-Update/Create-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Create | Contract"
          }
        },
        {
          path: "contracts/update/:id",
          name: "admin.contract.update",
          component: () =>
            import("@/view/pages/contract/Create-Or-Update/Update-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Update | Contract"
          }
        },
        {
          path: "contracts/detail/:id",
          name: "admin.contract.detail",
          component: () =>
            import("@/view/pages/contract/Detail/Detail-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Detail | Contract"
          }
        },
        {
          path: "expenses",
          name: "admin.expense",
          component: () => import("@/view/pages/expense/Expense"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "expense",
            title: "Expenses"
          }
        },
        {
          path: "expenses/create",
          name: "admin.expense.create",
          component: () =>
            import("@/view/pages/expense/Create-Or-Update/Create-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Create | Expense"
          }
        },
        {
          path: "expenses/update/:id",
          name: "admin.expense.update",
          component: () =>
            import("@/view/pages/expense/Create-Or-Update/Update-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Update | Expense"
          }
        },
        {
          path: "expenses/detail/:id",
          name: "admin.expense.detail",
          component: () => import("@/view/pages/expense/Detail/Detail-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Detail | Expense"
          }
        },
        {
          path: "proposal",
          name: "admin.proposal",
          component: () => import("@/view/pages/proposal/Proposal"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "proposal",
            title: "Proposals"
          }
        },
        {
          path: "proposal/create",
          name: "admin.proposal.create",
          component: () =>
            import("@/view/pages/proposal/Create-Or-Update/Create-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Create | Proposals"
          }
        },
        {
          path: "proposal/update/:id",
          name: "admin.proposal.update",
          component: () =>
            import("@/view/pages/proposal/Create-Or-Update/Update-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Update | Proposals"
          }
        },
        {
          path: "proposal/detail/:id",
          name: "admin.proposal.detail",
          component: () =>
            import("@/view/pages/proposal/Detail/Detail-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Detail | Proposals"
          }
        },
        {
          path: "payment",
          name: "admin.payment",
          component: () => import("@/view/pages/payment/Payment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "payment",
            title: "Payment"
          }
        },
        {
          path: "payment/create",
          name: "admin.payment.create",
          component: () => import("@/view/pages/payment/Create-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Create | Payment"
          }
        },
        {
          path: "payment/detail/:id",
          name: "admin.payment.detail",
          component: () => import("@/view/pages/payment/Detail-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment"
          }
        },
        {
          path: "equipment",
          name: "admin.equipment",
          component: () => import("@/view/pages/equipment/Equipment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "equipment",
            title: "Equipment"
          }
        },
        {
          path: "equipment/create",
          name: "admin.equipment.create",
          component: () => import("@/view/pages/equipment/Create-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Create | Equipment"
          }
        },
        {
          path: "equipment/update/:id",
          name: "admin.equipment.update",
          component: () => import("@/view/pages/equipment/Update-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Update | Equipment"
          }
        },
        {
          path: "equipment/detail/:id",
          name: "admin.equipment.detail",
          component: () => import("@/view/pages/equipment/Detail-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Detail | Equipment"
          }
        },
        {
          path: "product",
          name: "admin.product",
          component: () => import("@/view/pages/product/Product"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "product",
            title: "Product"
          }
        },
        {
          path: "product/create",
          name: "admin.product.create",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product"
          }
        },
        {
          path: "product/update/:id",
          name: "admin.product.update",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Update | Product"
          }
        },
        {
          path: "product/detail/:id",
          name: "admin.product.detail",
          component: () => import("@/view/pages/product/Detail-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Detail | Product"
          }
        },
        {
          path: "product/adjustment/create",
          name: "admin.product.adjustment.create",
          component: () =>
            import("@/view/pages/product/adjustment/Create-Adjustment"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product Adjustment"
          }
        },
        {
          path: "warranty",
          name: "admin.warranty",
          component: () => import("@/view/pages/warranty/Warranty"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "warranty",
            title: "Warranty"
          }
        },
        {
          path: "service",
          name: "admin.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service"
          }
        },
        {
          path: "service/create",
          name: "admin.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service"
          }
        },
        {
          path: "service/update/:id",
          name: "admin.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service"
          }
        },
        {
          path: "service/detail/:id",
          name: "admin.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service"
          }
        },
        {
          path: "job",
          name: "admin.job",
          component: () => import("@/view/pages/job/Job"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "job",
            title: "Job"
          }
        },
        {
          path: "job/create",
          name: "admin.job.create",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "job",
            title: "Create | Job"
          }
        },
        {
          path: "job/detail/:id",
          name: "admin.job.detail",
          component: () => import("@/view/pages/job/Detail-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "job",
            title: "Detail | Job"
          }
        },
        {
          path: "engineer",
          name: "admin.engineer",
          component: () => import("@/view/pages/engineer/Engineer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "engineer",
            title: "Engineer"
          }
        },
        {
          path: "engineer/create",
          name: "admin.engineer.create",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Create | Engineer"
          }
        },
        {
          path: "engineer/update/:id",
          name: "admin.engineer.update",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Update | Engineer"
          }
        },
        {
          path: "engineer/detail/:id",
          name: "admin.engineer.detail",
          component: () => import("@/view/pages/engineer/Detail-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Detail | Engineer"
          }
        },
        {
          path: "leave",
          name: "admin.leave",
          component: () => import("@/view/pages/leave/Leave"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "leave",
            title: "Leave"
          }
        },
        {
          path: "leave/create",
          name: "admin.leave.create",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Create-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Create | Leave"
          }
        },
        {
          path: "leave/update/:id",
          name: "admin.leave.update",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Update-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Update | Leave"
          }
        },
        {
          path: "leave/detail/:id",
          name: "admin.leave.detail",
          component: () => import("@/view/pages/leave/Detail/Detail-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Detail | Leave"
          }
        },
        {
          path: "report",
          name: "admin.report",
          component: () => import("@/view/pages/report/Report"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "report",
            title: "Report"
          }
        },
        {
          path: "report/:module",
          name: "admin.report.detail",
          component: () => import("@/view/pages/report/Report-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "report",
            title: "Report Detail"
          }
        }
      ]
    },
    {
      path: "/admin/login",
      name: "admin.login",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" }
    },
    {
      path: "/engineer",
      name: "engineer",
      redirect: { name: "engineer.dashboard" },
      component: () => import("@/view/engineer/Layout"),
      children: [
        {
          path: "dashboard",
          name: "engineer.dashboard",
          component: () => import("@/view/engineer/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard"
          }
        },
        {
          path: "calendar",
          name: "engineer.calendar",
          component: () => import("@/view/pages/Calendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Calendar"
          }
        },
        {
          path: "calendar/resource",
          name: "engineer.calendar.resource",
          component: () => import("@/view/pages/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar"
          }
        },
        {
          path: "project/costing/create",
          name: "engineer.project.costing.create",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Costing | Projects"
          }
        },
        {
          path: "project/costing/update/:id",
          name: "engineer.project.costing.update",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Costing | Projects"
          }
        },
        {
          path: "project/costing/detail/:id",
          name: "engineer.project.costing.detail",
          component: () =>
            import("@/view/pages/project/costing/DetailCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Costing | Projects"
          }
        },
        {
          path: "project",
          name: "engineer.project",
          component: () => import("@/view/pages/project/Project.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "project",
            title: "Projects"
          }
        },
        {
          path: "project/create",
          name: "engineer.project.create",
          component: () => import("@/view/pages/project/Create-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Project"
          }
        },
        {
          path: "project/update/:id",
          name: "engineer.project.update",
          component: () => import("@/view/pages/project/Update-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Project"
          }
        },
        {
          path: "project/detail/:id",
          name: "engineer.project.detail",
          component: () => import("@/view/pages/project/Detail-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Project"
          }
        },
        {
          path: "profile",
          name: "engineer.profile",
          redirect: { name: "engineer.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "engineer.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile"
              }
            },
            {
              path: "activity",
              name: "engineer.profile.activity",
              component: () => import("@/view/pages/profile/Activity.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "activity",
                title: "Activity"
              }
            }
          ]
        },
        {
          path: "customer",
          name: "engineer.customer",
          component: () => import("@/view/pages/customer/Customer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "customer",
            title: "Customer"
          }
        },
        {
          path: "customer/create",
          name: "engineer.customer.create",
          component: () => import("@/view/pages/customer/Create-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Create | Customer"
          }
        },
        {
          path: "customer/detail/:id",
          name: "engineer.customer.detail",
          component: () => import("@/view/pages/customer/Detail-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Detail | Customer"
          }
        },
        {
          path: "property",
          name: "engineer.property",
          component: () => import("@/view/pages/property/Property"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "property",
            title: "Property"
          }
        },
        {
          path: "property/create",
          name: "engineer.property.create",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Create | Property"
          }
        },
        {
          path: "property/detail/:id",
          name: "engineer.property.detail",
          component: () => import("@/view/pages/property/Detail-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Detail | Property"
          }
        },
        {
          path: "property/update/:id",
          name: "engineer.property.update",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Update | Property"
          }
        },
        {
          path: "quotation",
          name: "engineer.quotation",
          component: () => import("@/view/pages/quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "quotation",
            title: "Quotation"
          }
        },
        {
          path: "quotation/create",
          name: "engineer.quotation.create",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Create | Quotation"
          }
        },
        {
          path: "quotation/detail/:id",
          name: "engineer.quotation.detail",
          component: () => import("@/view/pages/quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Detail | Quotation"
          }
        },
        {
          path: "invoice",
          name: "engineer.invoice",
          component: () => import("@/view/pages/invoice/Invoice"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "invoice",
            title: "Invoice"
          }
        },
        {
          path: "invoice/create",
          name: "engineer.invoice.create",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Create | Invoice"
          }
        },
        {
          path: "invoice/detail/:id",
          name: "engineer.invoice.detail",
          component: () => import("@/view/pages/invoice/Detail-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Detail | Invoice"
          }
        },
        {
          path: "payment",
          name: "engineer.payment",
          component: () => import("@/view/pages/payment/Payment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "payment",
            title: "Payment"
          }
        },
        {
          path: "payment/create",
          name: "engineer.payment.create",
          component: () => import("@/view/pages/payment/Create-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Create | Payment"
          }
        },
        {
          path: "payment/detail/:id",
          name: "engineer.payment.detail",
          component: () => import("@/view/pages/payment/Detail-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment"
          }
        },
        {
          path: "equipment",
          name: "engineer.equipment",
          component: () => import("@/view/pages/equipment/Equipment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "equipment",
            title: "Equipment"
          }
        },
        {
          path: "equipment/create",
          name: "engineer.equipment.create",
          component: () => import("@/view/pages/equipment/Create-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Create | Equipment"
          }
        },
        {
          path: "equipment/update/:id",
          name: "engineer.equipment.update",
          component: () => import("@/view/pages/equipment/Update-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Update | Equipment"
          }
        },
        {
          path: "equipment/detail/:id",
          name: "engineer.equipment.detail",
          component: () => import("@/view/pages/equipment/Detail-Equipment"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Detail | Equipment"
          }
        },
        {
          path: "product",
          name: "engineer.product",
          component: () => import("@/view/pages/product/Product"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "product",
            title: "Product"
          }
        },
        {
          path: "product/create",
          name: "engineer.product.create",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product"
          }
        },
        {
          path: "product/update/:id",
          name: "engineer.product.update",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Update | Product"
          }
        },
        {
          path: "product/detail/:id",
          name: "engineer.product.detail",
          component: () => import("@/view/pages/product/Detail-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Detail | Product"
          }
        },
        {
          path: "warranty",
          name: "engineer.warranty",
          component: () => import("@/view/pages/warranty/Warranty"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "warranty",
            title: "Warranty"
          }
        },
        {
          path: "service",
          name: "engineer.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service"
          }
        },
        {
          path: "service/create",
          name: "engineer.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service"
          }
        },
        {
          path: "service/update/:id",
          name: "engineer.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service"
          }
        },
        {
          path: "service/detail/:id",
          name: "engineer.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service"
          }
        },
        {
          path: "visit",
          name: "engineer.visit",
          component: () => import("@/view/pages/visit/Visit"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit"
          }
        },
        {
          path: "visit/detail/:id",
          name: "engineer.visit.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit Detail"
          }
        },
        {
          path: "job",
          name: "engineer.job",
          component: () => import("@/view/pages/job/Job"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "job",
            title: "Job"
          }
        },
        {
          path: "job/create",
          name: "engineer.job.create",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "job",
            title: "Create | Job"
          }
        },
        {
          path: "job/detail/:id",
          name: "engineer.job.detail",
          component: () => import("@/view/pages/job/Detail-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "job",
            title: "Detail | Job"
          }
        },
        {
          path: "engineer",
          name: "engineer.engineer",
          component: () => import("@/view/pages/engineer/Engineer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "engineer",
            title: "Engineer"
          }
        },
        {
          path: "engineer/create",
          name: "engineer.engineer.create",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Create | Engineer"
          }
        },
        {
          path: "engineer/update/:id",
          name: "engineer.engineer.update",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Update | Engineer"
          }
        },
        {
          path: "engineer/detail/:id",
          name: "engineer.engineer.detail",
          component: () => import("@/view/pages/engineer/Detail-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Detail | Engineer"
          }
        },
        {
          path: "leave",
          name: "engineer.leave",
          component: () => import("@/view/pages/leave/Leave"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "leave",
            title: "Leave"
          }
        },
        {
          path: "leave/create",
          name: "engineer.leave.create",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Create-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Create | Leave"
          }
        },
        {
          path: "leave/update/:id",
          name: "engineer.leave.update",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Update-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Update | Leave"
          }
        },
        {
          path: "leave/detail/:id",
          name: "engineer.leave.detail",
          component: () => import("@/view/pages/leave/Detail/Detail-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Detail | Leave"
          }
        }
      ]
    },
    {
      path: "/engineer/login",
      name: "engineer.login",
      component: () => import("@/view/engineer/auth/Login"),
      meta: { requiresAuth: false, title: "Engineer | Login" }
    },
    {
      path: "/customer/quotation/approve",
      name: "customer.quotation.approve",
      component: () => import("@/view/customer/quotation/Approve"),
      meta: { requiresAuth: false, title: "Approve | Quotation | Customer" }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
      meta: { requiresAuth: false, title: "Page Not Found" }
    }
  ]
});
